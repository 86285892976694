import {posterThumbnail} from './components/poster_thumbnail.js';
import {loadingError, loadingSpinner} from './components/loading.js';
import {getAbout} from './components/load_about.js';


document.addEventListener('DOMContentLoaded', () => {

    const posterContent = document.getElementById("poster_content");

    getAbout();

    let getPosterGrid = function(){
  
      fetch("https://randywalton.ca/headless-cms/api/collections/get/killbot_projects?token=3880e75496efb98ddc147d71f677f3")
      .then(response => response.json())
      .then(array => {
        //console.log(array.entries);
        
        //preLoader(array.entries);

        //buildPosterGrid.init(array.entries);
        const posters = array.entries;

        posterContent.innerHTML = '';
        
        posters.forEach(poster => {
           //console.log(poster.status);
    
            let active_state = poster.status;
    
            if (active_state === "Published"){

                let content = posterThumbnail(poster.slug, poster.title, poster.slug);
                posterContent.append(content);
                
            }
    
        });

       })
       .catch(error => { 
            //console.log(error);
            posterContent.innerHTML = loadingError;
       });
  
    };
    
    getPosterGrid();



    let preloaded = 0

    function preLoader(entries) {
        console.log(entries, entries.length);
        for (var i = 0; i < entries.length; i++) {
            var tempImage = new Image();

            tempImage.addEventListener("load", progress, true);
            tempImage.src = `./posters/${entries.slug}.jpg`;
            console.log('tempImage');
        }
        //console.log(preloaded, singleProject.gallery);
    }

    function progress() {
        preloaded++;

        if (preloaded === entries.length) {
            //appendImages();
            console.log('preloaded');
        }
    }
    //preLoader();
  
  
  
});
  