
function posterThumbnail(proj_img, alt, proj_url) {
    
    const thePoster = document.createElement('figure');
    thePoster.className = 'content-block';

    const img = document.createElement('img');
    const a = document.createElement('a');

    img.alt = alt;
    img.src = `./posters/${proj_img}.jpg`;
    img.className = 'posterborder';

    a.href = `./poster.html?theposter=${proj_url}`;
    a.append(img);

    thePoster.append(a);

    return thePoster;
           


}

export {posterThumbnail}