
const about = document.getElementById("about");

let getAbout = function(){

    fetch("https://randywalton.ca/headless-cms/api/singletons/get/killbots_about?token=3880e75496efb98ddc147d71f677f3")
    .then(response => response.json())
    .then(content => {
      //console.log(content);

      about.innerHTML = content.overview;
    
      //buildPosterGrid.init(array.entries);
      

     })
     .catch(error => { 
          //console.log(error);
          posterContent.innerHTML = loadingError;
     });

  };

export {getAbout}